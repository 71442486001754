import Vue from "vue";

/*TITLE*/
document.title = "Edificio Orange Tree | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Edificio Orange Tree";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Edificio Orange Tree";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_residence-arizona--20230126040152.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo-1-vela-grey_limit-vela-grey--20230126040115.jpg";
Vue.prototype.$image_bath2 = "banyo-2-rodano-caliza_mosaico-rodano-caliza--20230126040135.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_residence-arizona--20230126040152.jpg",
  },
  {
    src: "salon_home-salzburg--20230126040159.jpg",
  },
  {
    src: "salon_home-kaprun--20230126040105.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-1-vela-grey_limit-vela-grey--20230126040115.jpg",
  },
  {
    src: "banyo-1-matika-bone_block-matika-bone--20230126040121.jpg",
  },
  {
    src: "banyo-1-bottega-white_spiga-bottega-white--20230126040127.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2-rodano-caliza_mosaico-rodano-caliza--20230126040135.jpg",
  },
  {
    src: "banyo-2-newport-natural_old-natural--20230126040141.jpg",
  },
  {
    src: "banyo-2-dover-caliza_dover-modern-line-caliza--20230126040149.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=avenida%20juan%20de%20borbón%20s%20n%20murcia%2030007",
    text: "Avenida Juan de Borbón S/N, Murcia, 30007",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=murcia%2030007",
    text: "Murcia, 30007",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:968901149",
    text: "968901149",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@levantya.com",
    text: "info@levantya.com",
  },
];
