<template>
  <div
    id="homeTourVirtual"
    :style="$vuetify.breakpoint.mdAndUp ? 'min-height: 500px' : ''"
  >
    <v-container fluid class="section">
      <v-row>
        <v-col cols="12">
          <header-section
            :title="$t('Tour Virtual')"
            :subtitle="$t('tour_vistual_Subtitle')"
          ></header-section>
        </v-col>
        <v-col cols="12">
          <iframe
            :src="this.$360"
            width="100%"
            :height="$vuetify.breakpoint.mdAndUp ? '900px' : '400px'"
            frameborder="0"
          ></iframe>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderSection from "../components/HeaderSection";

export default {
  name: "HomeTourVirtual",
  components: {
    HeaderSection,
  },
};
</script>

<style scoped></style>
