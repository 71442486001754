<template>
  <div>
    <home-hero />
    <home-intro />
    <home-gallery v-if="this.$image_sittingroom || this.$image_kitchen" />
    <home-tourVirtual v-if="this.$360" />
    <home-3DFilm v-if="this.$3dFilm" />
    <homeCatalog v-if="this.$catalog" />
    <homeSpaces v-if="this.$spaces" />
    <homeContact />
  </div>
</template>

<script>
import HomeHero from "../components/HomeHero";
import HomeIntro from "../components/HomeIntro";
import HomeGallery from "../components/HomeGallery";
import HomeTourVirtual from "../components/HomeTourVirtual";
import Home3DFilm from "../components/Home3DFilm";
import HomeCatalog from "../components/HomeCatalog";
import HomeSpaces from "../components/HomeSpaces";
import HomeContact from "../components/HomeContact";

export default {
  name: "HomeView",

  components: {
    HomeHero,
    HomeIntro,
    HomeGallery,
    HomeTourVirtual,
    Home3DFilm,
    HomeCatalog,
    HomeSpaces,
    HomeContact,
  },
};
</script>

<style scoped></style>
