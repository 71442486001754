<template>
  <div
    id="homeSpaces"
    class="section_background"
    :style="$vuetify.breakpoint.mdAndUp ? 'min-height: 500px' : ''"
  >
    <v-container fluid class="section">
      <v-row>
        <v-col cols="12">
          <header-section
            :title="this.getTitle"
            :subtitle="this.getSpaces1"
          ></header-section>
        </v-col>
        <v-col cols="12">
          <iframe
            :src="this.$spaces"
            width="100%"
            :height="$vuetify.breakpoint.mdAndUp ? '900px' : '400px'"
            frameborder="0"
            class="section_spaces"
          ></iframe>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderSection from "../components/HeaderSection";
import { i18n } from "@/plugins/i18n";

export default {
  name: "HomeSpaces",
  components: {
    HeaderSection,
  },
  computed: {
    getTitle: function () {
      return this.$typeProject == "SELECT"
        ? i18n.t("App personalización select")
        : i18n.t("App personalización partners");
    },
    getSpaces1: function () {
      return this.$typeProject == "SELECT"
        ? i18n.t("spaces1_select")
        : i18n.t("spaces1_partners");
    },
  },
};
</script>

<style scoped>
.section_spaces {
  background-color: white;
}
</style>
