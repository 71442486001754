<template>
  <div class="home-hero">
    <v-layout justify-center align-center column>
      <div
        class="d-block text-md-h1 text-h4 font-weight-black white--text text-xs-center"
        style="position: absolute; bottom: 50%"
      >
        {{ this.$title }}
      </div>
      <div style="position: absolute; bottom: 10px">
        <v-img
          :src="require('/public/porce_acabados.png')"
          max-width="260"
        ></v-img>
      </div>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "HomeHero",
};
</script>

<style scoped>
.home-hero {
  --opacidad-negro: 0.4;
  background: linear-gradient(
      rgba(0, 0, 0, var(--opacidad-negro)),
      rgba(0, 0, 0, var(--opacidad-negro))
    ),
    url("/public/resources/mainBackground.jpeg");
  background-position: center center;
  min-height: 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>
