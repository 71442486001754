import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
const messages = {
  es: {
    home: "HOME",
    "GALERIA 3D": "GALERÍA 3D",
    "TOUR VIRTUAL": "TOUR VIRTUAL",
    "3D FILM": "3D FILM",
    CATÁLOGO: "CATÁLOGO",
    "APP PERSONALIZACIÓN PARTNERS": "APP PERSONALIZACIÓN",
    "APP PERSONALIZACIÓN SELECT": "SELECT SPACE",
    CONTACTO: "CONTACTO",
    intro1:
      "Esta promoción ha confiado en la experiencia de Porcelanosa Grupo y sus más de 50 años de trayectoria en el sector residencial para que el 100% de los acabados de nuestras viviendas sean de calidad y puedas tener la máxima garantía.",
    intro2_partners:
      "Junto a Porcelanosa Partners, el área especializada en proyectos residenciales, hemos desarrollado las soluciones más apropiadas acorde a las necesidades del proyecto, contando siempre con el asesoramiento y supervisión directa de sus mejores profesionales y toda nuestra dedicación.",
    intro2_select:
      "Junto a Porcelanosa Select, el área especializada en proyectos residenciales, hemos desarrollado las soluciones más apropiadas acorde a las necesidades del proyecto, contando siempre con el asesoramiento y supervisión directa de sus mejores profesionales y toda nuestra dedicación.",
    "Galeria 3D": "Galería 3D",
    Galeria_subtitle:
      "Deja de imaginar y empieza a visualizar<br> las estancias de tu futura vivienda.",
    "Ver todos los acabados": "acabados <br> disponibles",
    SALÓN: "SALÓN",
    COCINA: "COCINA",
    "BAÑO 1": "BAÑO 1",
    "BAÑO 2": "BAÑO 2",
    FACHADA: "FACHADA",
    HABITACIÓN: "DORMITORIO",
    "Tour Virtual": "Tour Virtual",
    tour_vistual_Subtitle:
      "Recorrido virtual por las diferentes<br> estancias de la vivienda.",
    "3D Film": "3D Film",
    "3dfilm_Subtitle":
      "Experiencia audiovisual del interior<br> de la vivienda con la que ver los<br> ambientes con vida.",
    Catálogo: "Catálogo",
    catalog1:
      "Catálogo para ver la presentación<br> de la promoción residencial y elegir<br> acabados.",
    Descargar: "Descargar",
    "App personalización partners": "App personalización",
    "App personalización select": "Select Space",
    spaces1_partners:
      "App Porcelanosa Partners Spaces<br> para personalizar los ambientes del<br> residencial y seleccionar de acabados.",
    spaces1_select:
      "Select Space<br> para personalizar los ambientes del<br> residencial y seleccionar los acabados.",
    Contacto: "Contacto",
    "Dirección Promoción": "Dirección Promoción",
    "Dirección Showroom": "Dirección Showroom",
    "Todos los derechos reservados.": "Todos los derechos reservados.",
  },
  en: {
    home: "HOME",
    "GALERIA 3D": "3D GALLERY",
    "TOUR VIRTUAL": "VIRTUAL TOUR",
    "3D FILM": "3D FILM",
    CATÁLOGO: "CATALOG",
    "APP PERSONALIZACIÓN PARTNERS": "CUSTOMIZATION APP",
    "APP PERSONALIZACIÓN SELECT": "SELECT SPACE",
    CONTACTO: "CONTACT",
    intro1:
      "This promotion has relied on the experience of Porcelanosa Grupo and its more than 50 years of experience in the residential sector so that 100% of the finishes in our homes are of quality and you can have the maximum guarantee.",
    intro2_partners:
      "Together with Porcelanosa Partners, the area specialized in residential projects, we have developed the most appropriate solutions according to the needs of the project, always counting on the advice and direct supervision of its best professionals and all our dedication.",
    intro2_select:
      "Together with Porcelanosa Select, the area specialized in residential projects, we have developed the most appropriate solutions according to the needs of the project, always counting on the advice and direct supervision of its best professionals and all our dedication.",
    "Galeria 3D": "3D Gallery",
    Galeria_subtitle:
      "Stop imagining and start visualizing the rooms of your future home.",
    "Ver todos los acabados": "all finishes",
    SALÓN: "LIVING ROOM",
    COCINA: "KITCHEN",
    "BAÑO 1": "BATHROOM 1",
    "BAÑO 2": "BATHROOM 2",
    FACHADA: "FACADE",
    HABITACIÓN: "BEDROOM",
    "Tour Virtual": "Virtual Tour",
    tour_vistual_Subtitle:
      "Virtual tour of the different<br> rooms of the house.",
    "3D Film": "3D Film",
    "3dfilm_Subtitle":
      "Audiovisual experience of the interior<br> of the house with which to see the<br> environments alive.",
    Catálogo: "Catalog",
    catalog1:
      "Catalog to see the presentation<br> of the residential development and choose<br> finishes.",
    Descargar: "Download",
    "App personalización partners": "App customization",
    "App personalización select": "Select Space",
    spaces1_partners:
      "Porcelanosa Partners Spaces app<br> to customize residential settings and<br> select finishes.",
    spaces1_select:
      "Select Space<br> to customize residential settings and<br> select finishes.",
    Contacto: "Contact",
    "Dirección Promoción": "Promotion Address",
    "Dirección Showroom": "Showroom Address",
    "Todos los derechos reservados.": "All rights reserved.",
  },
  pt: {
    home: "HOME",
    "GALERIA 3D": "GALERIA 3D",
    "TOUR VIRTUAL": "TURNÊ VIRTUAL",
    "3D FILM": "FILME 3D",
    CATÁLOGO: "CATÁLOGO",
    "APP PERSONALIZACIÓN PARTNERS": "APLICATIVO DE PERSONALIZAÇÃO",
    "APP PERSONALIZACIÓN SELECT": "SELECT SPACE",
    CONTACTO: "CONTATO",
    intro1:
      "Esta promoção contou com a experiência da Porcelanosa Grupo e os seus mais de 50 anos de experiência no setor residencial para que 100% dos acabamentos das nossas casas sejam de qualidade e tenha a máxima garantia.",
    intro2_partners:
      "Junto com a Porcelanosa Partners, a área especializada em projetos residenciais, desenvolvemos as soluções mais adequadas às necessidades do projeto, contando sempre com o aconselhamento e supervisão direta dos seus melhores profissionais e toda a nossa dedicação.",
    intro2_select:
      "Junto com a Porcelanosa Select, a área especializada em projetos residenciais, desenvolvemos as soluções mais adequadas às necessidades do projeto, contando sempre com o aconselhamento e supervisão direta dos seus melhores profissionais e toda a nossa dedicação.",
    "Galeria 3D": "Galeria 3D",
    Galeria_subtitle:
      "Pare de imaginar e comece a visualizar os cômodos da sua futura casa.",
    "Ver todos los acabados": "todos os acabamentos",
    SALÓN: "SALA DE ESTAR",
    COCINA: "COZINHA",
    "BAÑO 1": "BANHEIRO 1",
    "BAÑO 2": "BANHEIRO 2",
    FACHADA: "FACHADA",
    HABITACIÓN: "QUARTO",
    "Tour Virtual": "Tour Virtual",
    tour_vistual_Subtitle: "Tour virtual pelos diferentes<br> cômodos da casa.",
    "3D Film": "3D Film",
    "3dfilm_Subtitle":
      "Experiência audiovisual do interior<br> da casa para ver os<br> ambientes vivos.",
    Catálogo: "Catálogo",
    catalog1:
      "Catálogo para ver a apresentação<br> do empreendimento residencial e escolher<br> acabamentos.",
    Descargar: "Baixar",
    "App personalización partners": "Personalização do aplicativo",
    "App personalización select": "Select Space",
    spaces1_partners:
      "Aplicativo Porcelanosa Partners Spaces<br> para personalizar ambientes residenciais<br> e selecionar acabamentos.",
    spaces1_select:
      "Select Space<br> para personalizar ambientes residenciais<br> e selecionar acabamentos.",
    Contacto: "Contato",
    "Dirección Promoción": "Endereço da promoção",
    "Dirección Showroom": "Endereço do Showroom",
    "Todos los derechos reservados.": "Todos os direitos reservados.",
  },
  it: {
    home: "HOME",
    "GALERIA 3D": "GALLERIA 3D",
    "TOUR VIRTUAL": "VISITA VIRTUALE",
    "3D FILM": "FILM 3D",
    CATÁLOGO: "CATALOGO",
    "APP PERSONALIZACIÓN PARTNERS": "APP PERSONALIZZAZIONE",
    "APP PERSONALIZACIÓN SELECT": "SELECT SPACE",
    CONTACTO: "CONTATTO",
    intro1:
      "Questa promozione è basata sull'esperienza di Porcelanosa Grupo e i suoi oltre 50 anni di storia nel settore residenziale affinché il 100% delle finiture delle nostre case sia di qualità per ottenere la massima garanzia.",
    intro2_partners:
      "Insieme a Porcelanosa Partners, l'area specializzata in progetti residenziali, abbiamo sviluppato le migliori soluzioni per le esigenze del progetto, facendo sempre affidamento sull’appassionata consulenza dei suoi migliori professionisti.",
    intro2_select:
      "Insieme a Porcelanosa Select, l'area specializzata in progetti residenziali, abbiamo sviluppato le migliori soluzioni per le esigenze del progetto, facendo sempre affidamento sull’appassionata consulenza dei suoi migliori professionisti.",
    "Galeria 3D": "Galleria 3D",
    Galeria_subtitle:
      "Smetti di immaginare e inizia a visualizzare le stanze della tua futura casa.",
    "Ver todos los acabados": "tutte le finiture",
    SALÓN: "SOGGIORNO",
    COCINA: "CUCINA",
    "BAÑO 1": "BAGNO 1",
    "BAÑO 2": "BAGNO 2",
    FACHADA: "FACCIATA",
    HABITACIÓN: "CAMERA",
    "Tour Virtual": "Virtual Tour",
    tour_vistual_Subtitle: "Tour virtuale delle diverse<br> stanze della casa.",
    "3D Film": "3D Film",
    "3dfilm_Subtitle":
      "Esperienza audiovisiva dell'interno<br> della casa con cui vedere<br> vivi gli ambienti.",
    Catálogo: "Catalogo",
    catalog1:
      "Catalogo per vedere la presentazione<br> dello sviluppo residenziale e scegliere<br> le finiture.",
    Descargar: "Scarica",
    "App personalización partners": "App Personalizzazione",
    "App personalización select": "Select Space",
    spaces1_partners:
      "App Porcelanosa Partners Spaces<br> per personalizzare gli ambienti residenziali<br> e selezionare le finiture.",
    spaces1_select:
      "Select Space<br> per personalizzare gli ambienti residenziali<br> e selezionare le finiture.",
    Contacto: "Contatto",
    "Dirección Promoción": "Indirizzo promozione",
    "Dirección Showroom": "Indirizzo showroom",
    "Todos los derechos reservados.": "Tutti i diritti riservati.",
  },
  fr: {
    home: "HOME",
    "GALERIA 3D": "GALERIE 3D",
    "TOUR VIRTUAL": "VISITE VIRTUELLE",
    "3D FILM": "3D FILM",
    CATÁLOGO: "CATALOGUE",
    "APP PERSONALIZACIÓN PARTNERS": "APPLICATION DE PERSONNALISATION",
    "APP PERSONALIZACIÓN SELECT": "SELECT SPACE",
    CONTACTO: "CONTACTER",
    intro1:
      "Cette promotion s'est appuyée sur l'expérience de Porcelanosa Grupo et ses 50 ans d'expérience dans le secteur résidentiel afin que 100% des finitions de nos maisons soient de qualité et que vous puissiez avoir la garantie maximale.",
    intro2_partners:
      "En collaboration avec Porcelanosa Partners, le domaine spécialisé dans les projets résidentiels, nous avons développé les solutions les plus appropriées en fonction des besoins du projet, en comptant toujours sur les conseils et la supervision directe de ses meilleurs professionnels et sur tout notre dévouement.",
    intro2_select:
      "En collaboration avec Porcelanosa Select, le domaine spécialisé dans les projets résidentiels, nous avons développé les solutions les plus appropriées en fonction des besoins du projet, en comptant toujours sur les conseils et la supervision directe de ses meilleurs professionnels et sur tout notre dévouement.",
    "Galeria 3D": "Galerie 3D",
    Galeria_subtitle:
      "Arrêtez d'imaginer et commencez à visualiser les pièces de votre future maison.",
    "Ver todos los acabados": "toutes les finitions",
    SALÓN: "SALON",
    COCINA: "CUISINE",
    "BAÑO 1": "SALLE DE BAIN 1",
    "BAÑO 2": "SALLE DE BAIN 2",
    FACHADA: "FAÇADE",
    HABITACIÓN: "CHAMBRE",
    "Tour Virtual": "Visite virtuelle",
    tour_vistual_Subtitle:
      "Visite virtuelle des différentes<br>pièces de la maison.",
    "3D Film": "3D Film",
    "3dfilm_Subtitle":
      "Expérience audiovisuelle de l'intérieur<br> de la maison avec laquelle<br> voir les environnements vivants.",
    Catálogo: "Catalogue",
    catalog1:
      "Catalogue pour voir la présentation<br> du développement résidentiel et choisir<br> les finitions.",
    Descargar: "Télécharger",
    "App personalización partners": "Personnalisation de l'application",
    "App personalización select": "Select Space",
    spaces1_partners:
      "Application Porcelanosa Partners Spaces<br> pour personnaliser les environnements<br> résidentiels et sélectionner les finitions.",
    spaces1_select:
      "Select Space<br> pour personnaliser les environnements<br> résidentiels et sélectionner les finitions.",
    Contacto: "Contactez-nous",
    "Dirección Promoción": "Adresse promotionnelle",
    "Dirección Showroom": "Adresse de la salle d'exposition",
    "Todos los derechos reservados.": "Tous droits réservés.",
  },
};
export const i18n = new VueI18n({
  locale: window.navigator.language.split("-")[0],
  fallbackLocale: "en",
  messages,
});
