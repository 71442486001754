<template>
  <div id="homecontact">
    <v-container fluid class="section_footer">
      <v-row>
        <v-col>
          <header-section :title="$t('Contacto')"></header-section>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <contact-section
            v-if="this.$promoter_address != ''"
            :title="$t('Dirección Promoción')"
            :items="this.$promoter_address"
          ></contact-section>
        </v-col>
        <v-col cols="12" md="3">
          <contact-section
            v-if="this.$showroom != ''"
            :title="$t('Dirección Showroom')"
            :items="this.$showroom"
          ></contact-section>
        </v-col>
        <v-col cols="12" md="3">
          <contact-section
            :title="$t('Contacto')"
            :items="this.$contact"
          ></contact-section>
        </v-col>
        <v-col cols="12" md="3">
          <div
            class="d-flex flex-row align-center justify-center justify-md-end"
          >
            <div v-if="this.$promotion_logo">
              <v-img
                max-height="200"
                max-width="150"
                :src="require('/public/resources/promotionLogo.png')"
                class="mr-4"
              ></v-img>
            </div>
            <div v-if="this.$promoter_logo">
              <v-img
                max-height="200"
                max-width="150"
                :src="require('/public/resources/promoterLogo.png')"
                class="mx-auto"
              ></v-img>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <div class="justify-center mt-5">
          <v-img
            max-height="200"
            max-width="250"
            :src="require('/public/prince.png')"
            class="mx-auto"
          ></v-img>
          <p style="font-size: 12px" class="text-center">
            <b>
              &copy; {{ new Date().getFullYear() }} PORCELANOSA Grupo A.I.E.
            </b>
            <br v-if="$vuetify.breakpoint.xsOnly" />
            {{ $t("Todos los derechos reservados.") }}
          </p>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderSection from "../components/HeaderSection";
import ContactSection from "../components/ContactSection";

export default {
  name: "HomeContact",
  components: {
    HeaderSection,
    ContactSection,
  },
};
</script>

<style scoped></style>
