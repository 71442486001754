<template>
  <div>
    <h4>{{ title }}</h4>
    <div v-for="item in items" :key="item.id">
      <span class="text-body-2">
        <v-icon small color="black">{{ item.icon }}</v-icon>
        <a
          :href="item.link"
          target="_blank"
          rel="noopener noreferrer"
          style="color: black; font-weight: 400; text-decoration: none"
        >
          {{ item.text }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeContact",
  props: ["title", "items"],
};
</script>

<style scoped>
a:hover {
  color: rgb(101, 101, 101) !important;
}
</style>
