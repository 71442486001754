<template>
  <div
    id="homeGallery"
    class="section_background"
    :style="$vuetify.breakpoint.mdAndUp ? 'min-height: 500px' : ''"
  >
    <v-container fluid class="section">
      <v-row>
        <v-col cols="12">
          <header-section
            :title="$t('Galeria 3D')"
            :subtitle="$t('Galeria_subtitle')"
          ></header-section>
        </v-col>
      </v-row>
      <v-row :class="$vuetify.breakpoint.mdAndUp ? 'flowRoot' : ''">
        <more-image
          v-if="this.$image_sittingroom != ''"
          :md="this.$gallery_grid_sittingroom"
          height="750"
          :image_prop="this.$image_sittingroom"
          :title="$t('SALÓN')"
          :items="this.$gallery_images_sittingroom"
        >
        </more-image>
        <more-image
          v-if="this.$image_kitchen != ''"
          :md="this.$gallery_grid_kitchen"
          height="363"
          :image_prop="this.$image_kitchen"
          :title="$t('COCINA')"
          :items="this.$gallery_images_kitchen"
        >
        </more-image>
        <more-image
          v-if="this.$image_bath1 != ''"
          :md="this.$gallery_grid_bath1"
          height="363"
          :image_prop="this.$image_bath1"
          :title="$t('BAÑO 1')"
          :items="this.$gallery_images_bath1"
        >
        </more-image>
        <more-image
          v-if="this.$image_bath2 != ''"
          :md="this.$gallery_grid_bath2"
          height="363"
          :image_prop="this.$image_bath2"
          :title="$t('BAÑO 2')"
          :items="this.$gallery_images_bath2"
        >
        </more-image>
        <more-image
          v-if="this.$image_room != ''"
          :md="this.$gallery_grid_room"
          height="363"
          :image_prop="this.$image_room"
          :title="$t('HABITACIÓN')"
          :items="this.$gallery_images_room"
        >
        </more-image>
        <more-image
          v-if="this.$image_facade != ''"
          :md="this.$gallery_grid_facade"
          height="363"
          :image_prop="this.$image_facade"
          :title="$t('FACHADA')"
          :items="this.$gallery_images_facade"
        >
        </more-image>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MoreImage from "../components/MoreImage";
import HeaderSection from "../components/HeaderSection";

export default {
  name: "HomeGallery",
  components: {
    MoreImage,
    HeaderSection,
  },
};
</script>

<style scoped>
.flowRoot {
  display: flow-root !important;
}
</style>
