<template>
  <div>
    <h4 class="title_section">
      {{ title }}
    </h4>
    <p v-if="subtitle" v-html="subtitle" class="subtitle_section"></p>
  </div>
</template>

<script>
export default {
  name: "HeaderSection",
  props: ["title", "subtitle"],
};
</script>

<style scoped></style>
