<template>
  <v-col cols="12" :md="md" class="float-md-left">
    <v-card elevation="0" tile>
      <v-img
        :src="'/resources/' + this.image_prop"
        class="align-end"
        style="cursor: pointer"
        :height="$vuetify.breakpoint.mdAndUp ? height : '40vh'"
        @click="isCarrouselOpen = !isCarrouselOpen"
      >
        <v-card-title class="d-flex justify-space-between cover pa-5">
          <h2>
            {{ title }}
          </h2>
          <v-btn text>
            <v-icon left large class="pr-2"> mdi-plus-circle-outline </v-icon>
            <h4
              class="text-left text-lowercase"
              style="float: right; line-height: 120%"
              v-html="$t('Ver todos los acabados')"
            ></h4>
          </v-btn>
        </v-card-title>
      </v-img>
      <v-dialog
        v-model="isCarrouselOpen"
        max-width="1200px"
        @click:outside="isCarrouselOpen = false"
      >
        <v-carousel class="maxScreenCarrousel" cycle>
          <v-carousel-item
            v-for="(item, i) in this.items"
            :key="i"
            :src="'/resources/' + item.src"
          >
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "MoreImage",
  props: ["md", "height", "image_prop", "title", "items"],
  data: () => ({
    isCarrouselOpen: false,
  }),
};
</script>

<style>
.cover {
  background: rgba(219, 219, 219, 0.6);
}
h2 {
  font-size: 1.5rem;
}

@media (max-width: 2000px) {
  .maxScreenCarrousel {
    height: 90vh !important;
  }
  .maxScreenCarrousel .v-image {
    height: 100% !important;
  }
}

@media (min-width: 2001px) {
  .maxScreenCarrousel {
    height: 100% !important;
  }
  .maxScreenCarrousel .v-image {
    height: 100% !important;
  }
}
</style>
