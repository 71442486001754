<template>
  <v-app app>
    <app-navigation></app-navigation>
    <v-main class="pt-0 pt-sm-0 pt-xs-0 pt-md-0 pt-lg-0 pt-xl-0">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppNavigation from "@/components/AppNavigation";

export default {
  name: "App",
  components: {
    AppNavigation,
  },

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
$font-family: "Lato";

.v-application {
  [class*="text-"] {
    font-family: $font-family, sans-serif !important;
  }
  [class*="body-"] {
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}
div.section {
  padding: 5% 10%;
}
div.section_footer {
  padding: 5% 10% 0px 10%;
}
h4.title_section {
  display: inline-block;
  font-weight: 900;
  font-size: 2.125rem;
  border: 1px solid black;
  padding: 0px 12px;
  margin-bottom: 15px;
}
p.subtitle_section {
  display: block;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.15rem;
}
.section_background {
  background-color: #f1ece8;
}
</style>
